@use 'sass:map';
@import 'styles/_colors.scss';
@import 'styles/_spacing.scss';
@import 'styles/_breakpoints.scss';

.App {
  position: relative;
  margin: 0;
  color: map.get($text-colors, "main");
  background: radial-gradient(69.07% 69.07% at 51.54% 24.26%, #56456B 0.01%, #070707 100%);
  padding: 0;
  height: 100%;

  a {
    color: map.get($text-colors, "secondary");
    text-decoration: none;
    border-bottom: 1px solid map.get($text-colors, "secondary");

    &:hover {
      opacity: .6;
    }

    &:active {
      opacity: .8;
    }
  }

  .bg-shapes {
    position: absolute;
    top: 0;
    left: 0;
    background: url(../public/images/bg-shapes.svg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
  }

  .bg-noise {
    position: absolute;
    top: 0;
    left: 0;
    background: url(../public/images/bg-noise.webp) repeat;
    width: 100%;
    height: 100vh;
    opacity: .1;
  }

  .scroll {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: translate3d(0, 0, 0);
  }

  .App-header {
    margin: {
      top: map.get($spacing, "4");
      bottom: map.get($spacing, "2");
    };

    padding-left: map.get($spacing, "3");
    padding-right: map.get($spacing, "3");

    @media (max-width: $layout-breakpoint-medium) {
      padding-left: map.get($spacing, "2");
      padding-right: map.get($spacing, "2");
    }

    .subheader {
      padding-right: 5vw;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $layout-breakpoint-medium) {
        flex-wrap: wrap;
        padding-right: 0;
      }
    }

    .itemsLoader {
      display: flex;
      justify-content: center;
    }

    .Logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: map.get($spacing, "4");

      h1 {
        font-weight: 600;
        font-size: 1.4em;
        letter-spacing: 1px;
      }

      figure {
        width: 50px;
        height: 50px;
        background: url("../public/images/logo.png");
        background-size: cover;
        background-position: center;
        margin: 0;
        margin-right: map.get($spacing, "1");
      }
    }

    h2 {
      font-weight:lighter;
      font-size: 60px;
      letter-spacing: 2px;
      margin: 0;
    }
  }

  main {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10vw;
    padding-right: 5vw;
    justify-content: flex-end;

    .itemsLoader {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    @media (max-width: $layout-breakpoint-large) {
      padding-left: 0;
      padding-right: 0;
      justify-content: space-around;
    }
  }


  @media (max-width: $layout-breakpoint-medium) {
    background-size: contain;
  }
}
