@use 'sass:map';
@import 'styles/_colors.scss';

$transition-duration: 3.5s;
$path-length: 50px; 

.mainLoader {
  overflow: visible;
  width: 50px;
  height: 50px;
  
  g {    
    &:nth-child(2) {
      animation-delay: $transition-duration / 4;
      
      path {
        animation-delay: $transition-duration / 4;
        stroke-dasharray: 0px $path-length + 1;
        stroke-dashoffset: 1px;
      }
    }
  }
  
  path{
    stroke: url(#gradient);
    stroke-width: 10px;
    stroke-linecap: round;
    fill: none;
    stroke-dasharray: 0 $path-length;
    stroke-dashoffset: 0;
    animation: rotate $transition-duration cubic-bezier(0.8, 0, 0.2, 1) infinite;
  }
}

#gradient {
  --color-stop1: #{map.get($loader-colors, "primary")};
  --color-stop2: #{map.get($loader-colors, "secondary")};
}

@keyframes rotate {
  0% {
    stroke-dasharray: 0 $path-length;
    stroke-dashoffset: 0;
  }
  25% {
    stroke-dasharray: $path-length $path-length;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: $path-length $path-length;
    stroke-dashoffset: -($path-length - 1);
  }
  75% {
    stroke-dasharray: $path-length $path-length;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 0 $path-length;
    stroke-dashoffset: 0;
  }
}
