@use 'sass:map';
@import './_colors.scss';

@mixin button($theme: DarkGray) {
  color: map.get($text-colors, "main");
  width: 163px;
  position: relative;
  background: url("../../../public/images/control-border.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  border: none;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(152deg, rgba(211,94,220,1) -80%, rgba(29,34,35,0) 80%);
    border-radius: 30px;
  }

  &:hover {
    &:before {
      background: linear-gradient(152deg, rgba(211,94,220,1) -80%, rgba(211,94,220,0.2) 80%);
    }
  }
}
