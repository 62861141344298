@import-normalize;

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html, body, div#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
