@use 'sass:map';
@import 'styles/_colors.scss';
@import 'styles/_spacing.scss';
@import 'styles/_breakpoints.scss';
@import 'styles/_mixins.scss';

.ItemDetails {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  background: rgba(43, 51, 64, .85);
  overflow: hidden;
  border-radius: map.get($spacing, "3");
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.45);

  @keyframes show {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  animation: show .5s;

  .itemPic {
    width: 100%;
    height: 60%;
    margin: 0;
    box-shadow: 0px 0px 25px 0px map.get($theme-colors, "shadow") inset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all .7s ease;
  }

  .description {
    height: 40%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    color: map.get($text-colors, "main");
    padding: map.get($spacing, "3");
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.45) inset;

    @media (max-width: $layout-breakpoint-small) {
      padding: map.get($spacing, "1");
    }

    .author {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: map.get($spacing, "2");

      .authorData {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 80%;

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10pt;
          white-space: nowrap;
          padding: 2px 0;
        }

        .authorAvatar {
          width: 40px;
          height: 40px;
          margin: 0;
          margin-right: map.get($spacing, "2");
          border-radius: 10px;
          box-shadow: 0px 4px 15px 0px map.get($theme-colors, "shadow");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center center;
        }

        .date {
          color: map.get($text-colors, "shadowed");
        }
      }

      .views {
        display: flex;
        background: url(../../../public/icons/views.svg) no-repeat;
        background-size: 15px 15px;
        background-position: left center;
        padding-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 8pt;
        line-height: 10pt;
      }
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: map.get($text-colors, "shadowed");
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;

      .priceVal {
        font-size: 14px;
        line-height: 25px;
      }

      .name {
        padding-right: map.get($spacing, "1");
      }
    }

    .activity {
      display: flex;
      flex-direction: row;
      transition: all .7s ease;
      justify-content: flex-end;

      .likes {
        display: flex;
        background: url(../../../public/icons/likes.svg) no-repeat;
        background-size: 15px 15px;
        background-position: left center;
        padding-left: 20px;
        font-size: 7pt;
        line-height: 13pt;
        color: map.get($text-colors, "secondary");
      }

      .comments {
        display: flex;
        background: url(../../../public/icons/comments.svg) no-repeat;
        background-size: 15px 15px;
        background-position: left center;
        padding-left: 20px;
        font-size: 7pt;
        line-height: 13pt;
        margin-left: map.get($spacing, "3");
        color: map.get($text-colors, "shadowed");
      }
    }

    .actions {
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .buy {
        @include button;
        padding: 23px 0;
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;

        .icon {
          display: inline-block;
          width: 17px;
          height: 17px;
          background: url(../../../public/icons/basket.svg) no-repeat;
          background-size: contain;
        }
      }

      .close {
        @include button;
        padding: 23px 0;
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        background: rgba(0,0,0,.2);

        .icon {
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url(../../../public/icons/close-button-icon.svg) no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

