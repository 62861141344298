@use 'sass:map';
@import 'styles/_colors.scss';
@import 'styles/_spacing.scss';
@import 'styles/_breakpoints.scss';

.Item {
  position: relative;
  width: 250px;
  height: 350px;
  flex-wrap: wrap;
  margin: map.get($spacing, "3");
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &.hidden {
    visibility: hidden;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    border-radius: 40px;
    box-shadow: 0 4px 35px 0 map.get($theme-colors, "shadow");
    background: rgba(43, 51, 64, .85);
    transition: all .7s ease;
    display: flex;
    flex-direction: column;
  }

  .itemPic {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 0;
    box-shadow: 0px 0px 25px 0px map.get($theme-colors, "shadow") inset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all .7s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    color: map.get($text-colors, "main");
    padding: map.get($spacing, "2");
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.45) inset;
    text-align: left;

    .author {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: map.get($spacing, "2");

      .authorData {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 80%;

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10pt;
          white-space: nowrap;
          padding: 2px 0;
        }

        .authorAvatar {
          width: 40px;
          height: 40px;
          margin: 0;
          margin-right: map.get($spacing, "2");
          border-radius: 10px;
          box-shadow: 0px 4px 15px 0px map.get($theme-colors, "shadow");
          background-image: url(../../../public/images/dummy-avatar.webp);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center center;
        }

        .date {
          color: map.get($text-colors, "shadowed");
        }
      }

      .views {
        display: flex;
        background: url(../../../public/icons/views.svg) no-repeat;
        background-size: 15px 15px;
        background-position: left center;
        padding-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 8pt;
        line-height: 10pt;
      }
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: map.get($text-colors, "shadowed");
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;

      .priceVal {
        font-size: 14px;
        line-height: 25px;
      }

      .name {
        padding-right: map.get($spacing, "1");
      }
    }

    .activity {
      display: flex;
      flex-direction: row;
      visibility: hidden;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all .7s ease;

      .likes {
        display: flex;
        background: url(../../../public/icons/likes.svg) no-repeat;
        background-size: 15px 15px;
        background-position: left center;
        padding-left: 20px;
        font-size: 7pt;
        line-height: 13pt;
        color: map.get($text-colors, "secondary");
      }

      .comments {
        display: flex;
        background: url(../../../public/icons/comments.svg) no-repeat;
        background-size: 15px 15px;
        background-position: left center;
        padding-left: 20px;
        font-size: 7pt;
        line-height: 13pt;
        margin-left: map.get($spacing, "3");
        color: map.get($text-colors, "shadowed");
      }
    }
  }

  @media (max-width: $layout-breakpoint-medium) {
    margin: map.get($spacing, "1");
    width: 45%;
  }

  @media (min-width: $layout-breakpoint-medium) {
    &:hover {
      .content {
        height: calc(100% + 50px);
        background: rgba(43, 51, 64, 1);

        .description {
          padding-bottom: map.get($spacing, "3");
        }
      }

      .itemPic {
        transform: scale(1.1);
      }

      .price .name {
        color: map.get($text-colors, "main");
      }

      .activity {
        visibility: visible;
        height: 20px;
        opacity: 1;
      }
    }

  }

  @media (max-width: $layout-breakpoint-small) {
    width: 90%;
  }

}
